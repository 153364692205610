import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ElektronikPremiumCalculationService
} from '../../../../../../../../src/app/features/calculator-overview/services/elektronik-premium-calculation.service';

@Component({
  selector: 'app-elektronik-calculator',
  standalone: true,
  imports: [
    CurrencyPipe,
    PaginatorModule,
    ReactiveFormsModule
  ],
  templateUrl: './elektronik-calculator.component.html',
  styleUrl: './elektronik-calculator.component.scss',
  providers: [ElektronikPremiumCalculationService]
})
export class ElektronikCalculatorComponent implements OnInit {

  @Output() requestOffer = new EventEmitter<{ initialPrice: number, premium: number }>();

  fb = inject(FormBuilder);
  techPremiumCalculationService = inject(ElektronikPremiumCalculationService);

  form = this.fb.group<ElektronikCalculatorFormModel>({
    initialPrice: new FormControl<number | null>(null, [Validators.required])
  });
  premium: number | null = null;

  ngOnInit() {
    this.form
      .valueChanges
      .subscribe(value => {
        if (value.initialPrice !== null) {
          this.premium = this.techPremiumCalculationService.getPremium(value.initialPrice!);
        }
      });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.requestOffer.emit({
      initialPrice: this.form.getRawValue().initialPrice!,
      premium: this.premium!
    });
  }
}

export interface ElektronikCalculatorFormModel {
  initialPrice: FormControl<null | number>;
}

