import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ElektronikProductFormModel } from '../elektronik-form-wrapper/elektronik-form-wrapper.component';
import { ElektronikProductGroupEnum } from '../../models/elektronik-product-group.enum';

@Component({
  selector: 'app-product-data',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './product-data.component.html',
  styleUrl: './product-data.component.scss'
})
export class ProductDataComponent {

  @Input() formGroup!: FormGroup<ElektronikProductFormModel>;
  protected readonly ProductGroupEnum = ElektronikProductGroupEnum;
}
