<ng-container [formGroup]="formGroup">
  <h4 class="mb-3">Zu versicherndes Produkt</h4>

  <div class="row g-3">
    <div class="col-sm-4">
      <label
        for="productGroup"
        class="form-label">Warengruppe</label>

      <select
        [formControl]="formGroup.controls.productGroup"
        class="form-select"
        id="productGroup">

        <option [ngValue]="ProductGroupEnum.SMARTPHONE">Handy / Smartphone</option>
        <option [ngValue]="ProductGroupEnum.TABLET">Tablet</option>
        <option [ngValue]="ProductGroupEnum.LAPTOP">Notebook</option>
        <option [ngValue]="ProductGroupEnum.CAMERA">Kamera / Fotoapperat</option>
      </select>
    </div>

    <div class="col-sm-4">
      <label
        for="brand"
        class="form-label">Marke</label>

      <input
        [formControl]="formGroup.controls.brand"
        type="text"
        class="form-control"
        id="brand"/>
    </div>

    <div class="col-sm-4">
      <label
        for="model"
        class="form-label">Modell</label>

      <input
        [formControl]="formGroup.controls.model"
        type="text"
        class="form-control"
        id="model"/>
    </div>

    <div class="col-sm-4">
      <label
        for="serialNumber"
        class="form-label">Serien- bzw. IMEI-Nummer</label>

      <input
        [formControl]="formGroup.controls.serialNumber"
        type="text"
        class="form-control"
        id="serialNumber"/>
    </div>
  </div>
</ng-container>
