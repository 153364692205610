@if (!noPermission) {
  <div>
    <app-page-title
      [title]="'Elektronik Versicherung abschließen'"></app-page-title>

    @if (showRechner) {
      <div class="col-12 col-lg-6 m-auto">
        <app-elektronik-calculator
          (requestOffer)="onRequestOffer($event)"></app-elektronik-calculator>
      </div>
    } @else {
      @if (!submitted) {
        <app-elektronik-form-wrapper
          [premium]="premium"
          [price]="price"
          [servoAdvertiserId]="servoAdvertiserId"
          (submitted)="submitted = true"></app-elektronik-form-wrapper>
      }
    }

    @if (submitted) {
      <div
        class="alert alert-success text-center"
        role="alert">
        Ihr Antrag wurde erfolgreich versendet! <br />
        Sie können diese Seite nun schließen.
      </div>
    }
  </div>
}
