import { Routes } from '@angular/router';
import { ElektronikPageComponent } from './features/insurance/pages/elektronik-page/elektronik-page.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => ElektronikPageComponent
  },
  {
    path: 'public',
    loadComponent: () => ElektronikPageComponent
  },
  {
    path: 'stella',
    loadComponent: () => ElektronikPageComponent
  }
];
