import { Injectable } from '@angular/core';
import { ElektronikSubmitModel } from '../models/elektronik-submit.model';
import { Observable } from 'rxjs';
import { ApiService } from '@base/services/api.service';

@Injectable()
export class ElektronikApiService extends ApiService {

  protected get serviceEndpoint(): string {
    return `${this.endpointBrokernet}/api/servo/elektronik/public`;
  }

  submit(data: ElektronikSubmitModel): Observable<ElektronikSubmitModel> {
    return this.http.post<ElektronikSubmitModel>(this.serviceEndpoint + `/onboarding`, data);
  }
}
