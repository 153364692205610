<ng-container [formGroup]="formGroup">
  <h4 class="mb-3">Einverständniserklärung</h4>

  <div>
    <div class="form-check">
      <input
        [formControl]="formGroup.controls.communication"
        class="form-check-input"
        type="checkbox"
        id="communication">

      <label
        class="form-check-label"
        for="communication">
        Ich stimme der Vereinbarung zur elektronischen Kommunikation zu
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/05/Vereinbarung-zur-elektronischen-Kommunikation.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.gdpr"
        class="form-check-input"
        type="checkbox"
        id="gdpr">

      <label
        class="form-check-label"
        for="gdpr">
        Hiermit akzeptiere ich die Datenschutzerklärung
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/datenschutzerklaerung/"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.commonConditions"
        class="form-check-input"
        type="checkbox"
        id="commonConditions">

      <label
        class="form-check-label"
        for="commonConditions">
        Hiermit akzeptiere ich die Allgemeinen Vertragsbedingungen
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/09/AVB_Elektronik_final.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.informationLetter"
        class="form-check-input"
        type="checkbox"
        id="informationLetter">

      <label
        class="form-check-label"
        for="informationLetter">
        Ich habe das Informationsblatt für Versicherungsprodukte gelesen und zu Kenntnis genommen
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/09/IPID-ID-GO_Elektronik_final.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.sepaAllowed"
        class="form-check-input"
        type="checkbox"
        id="sepaAllowed">

      <label
        class="form-check-label"
        for="sepaAllowed">
        Ich ermächtige die Servo GmbH, Zahlungen zu folgenden Bedingungen von meinem Konto mittels SEPA-Lastschrift einzuziehen
      </label>

      <br/>

      <small class="text-muted">
        <a
          href="https://myservo.at/wp-content/uploads/2021/06/Sepa-Mandat-Vorlage-Privat-Bestaetigung-fuer-Homepage.pdf"
          target="_blank">
          Download hier möglich
        </a>
      </small>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.rightOfWithdrawal"
        class="form-check-input"
        type="checkbox"
        id="rightOfWithdrawal">

      <label
        class="form-check-label"
        for="rightOfWithdrawal">
        Widerrufs-Recht: Ich weiß, dass ich innerhalb von 14 Tagen ohne Grund von diesem Vertrag zurücktreten kann
      </label>
    </div>

    <div class="form-check">
      <input
        [formControl]="formGroup.controls.wishesAndNeedsFulfilled"
        class="form-check-input"
        type="checkbox"
        id="wishesAndNeedsFulfilled">

      <label
        class="form-check-label"
        for="wishesAndNeedsFulfilled">
        Dieses Produkt entspricht meinen Wünschen und Bedürfnissen.
      </label>

      <br/>

      <small class="text-muted">
        <a href="mailto:office@myservo.at"
           target="_blank">
          Falls nicht, kannst du uns gerne hier kontaktieren.
        </a>
      </small>
    </div>
  </div>
</ng-container>
