import { Component, OnInit } from '@angular/core';
import { ElektronikFormWrapperComponent } from '../../components/elektronik-form-wrapper/elektronik-form-wrapper.component';
import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import {
  KaufpreisCalculatorComponent
} from '../../../../../../../servo-kaufpreis/src/app/features/insurance/components/kaufpreis-calculator/kaufpreis-calculator.component';
import {
  KaufpreisFormWrapperComponent
} from '../../../../../../../servo-kaufpreis/src/app/features/insurance/components/kaufpreis-form-wrapper/kaufpreis-form-wrapper.component';
import { ElektronikCalculatorComponent } from '../../components/elektronik-calculator/elektronik-calculator.component';

@Component({
  selector: 'app-elektronik-page',
  standalone: true,
  imports: [
    PageTitleComponent,
    ElektronikFormWrapperComponent,
    KaufpreisCalculatorComponent,
    KaufpreisFormWrapperComponent,
    ElektronikCalculatorComponent
  ],
  templateUrl: './elektronik-page.component.html',
  styleUrl: './elektronik-page.component.scss'
})
export class ElektronikPageComponent implements OnInit {

  submitted = false;

  premium = 0;
  price = 0;

  servoAdvertiserId: string | null = null;

  showRechner = false;
  noPermission = false;

  ngOnInit() {
    const url = new URL(window.location.href);

    if (url.href.includes('public')) {
      this.handleCustomerViaReferrer(url);
    } else if (url.href.includes('stella')) {
      this.handleCustomerViaApp(url);
    } else {
      this.handleCustomerViaMakler(url);
    }
  }

  private handleCustomerViaReferrer(url: URL): void {
    if (!url.searchParams.has('referrerId')) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);
    this.showRechner = true;
  }

  private handleCustomerViaMakler(url: URL): void {
    this.showRechner = false;

    if (!this.hasUrlAdvertiserId(url) || !url.searchParams.has('premium') || !url.searchParams.has('price')) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);

    this.price = Number(url.searchParams.get('price'));
    this.premium = Number(url.searchParams.get('premium'));
    this.noPermission = false;
  }

  private handleCustomerViaApp(url: URL): void {
    if (!url.searchParams.has('advertiserId')) {
      this.noPermission = true;
      return;
    }

    this.servoAdvertiserId = this.getAdvertiserId(url);
    this.showRechner = true;
  }

  onRequestOffer(event: { initialPrice: number, premium: number }): void {
    this.premium = event.premium;
    this.price = event.initialPrice;
    this.showRechner = false;
  }

  // there are still some qr-codes which have an old maklerId / referrerId instead of the new advertiserId
  hasUrlAdvertiserId(url: URL): boolean {
    return url.searchParams.has('maklerId') || url.searchParams.has('advertiserId') || url.searchParams.has('referrerId');
  }

  getAdvertiserId(url: URL): string | null {
    if (url.searchParams.has('advertiserId')) {
      return url.searchParams.get('advertiserId')!;
    }

    if (url.searchParams.has('maklerId')) {
      return url.searchParams.get('maklerId')!;
    }

    if (url.searchParams.has('referrerId')) {
      return url.searchParams.get('referrerId')!;
    }

    return null;
  }
}
